<template>
    <el-row :gutter="160">
        <el-col :span="24" style="text-align: left;">
            <el-button type="primary" icon="el-icon-plus" @click="onClickAddSeason">新增赛季</el-button>
        </el-col>
        <el-col :span="24">
            <el-table :data="seasonList" :height="tableHeight" border style="width: 100%"
                :row-class-name="tableRowClassName" :cell-style="{padding:4+'px'}" class="serverlist">
                <el-table-column prop="id" label="ID" width="50px"> </el-table-column>
                <el-table-column prop="stype" label="类型名" show-overflow-tooltip width="150px"></el-table-column>              
                <el-table-column prop="sno" label="赛季轮次" show-overflow-tooltip width="90px"></el-table-column>
                <el-table-column prop="cur" label="当前赛季" show-overflow-tooltip></el-table-column>
                <el-table-column prop="next" label="下一赛季" show-overflow-tooltip></el-table-column>
                <el-table-column label="赛季时间" show-overflow-tooltip width="152px">
                    <template slot-scope="scope">
                        {{'持续'+scope.row.days+'天，间隔'+scope.row.space+'天'}}
                    </template>
                </el-table-column>                
                <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip width="200px"></el-table-column>
                <el-table-column label="操作" width="280" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onClickEditSeason(scope.row)" type="primary" icon="el-icon-edit"
                            size="small">编辑赛季</el-button>
                        <el-button @click.native.prevent="onClickSeasonGroup(scope.row)" type="success"
                            icon="el-icon-menu" size="small">管理分组</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
        <!-- ========分组管理弹框BEGIN====== -->
        <el-dialog :title="formTitleSeasonGroup" :visible.sync="dialogGroupForm" width="900px" :close-on-click-modal="false" center
            class="formparams">
            <el-button type="primary" icon="el-icon-plus" @click="onClickAddGroup">新增分组</el-button>
            <el-table :data="groupList" height="520px" border style="width: 100%"
                :row-class-name="tableRowClassName" :cell-style="{padding:4+'px'}" class="serverlist">
                <el-table-column prop="id" label="ID" width="50px"> </el-table-column>
                <el-table-column prop="title" label="分组名称" show-overflow-tooltip></el-table-column>
                <el-table-column label="区服数量" show-overflow-tooltip width="80px">
                    <template slot-scope="scope">
                        <div>{{scope.row.servers.length}}</div>
                    </template>
                </el-table-column>                
                <el-table-column prop="desc" label="备注" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" width="280" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onClickEdit(scope.row)" type="primary" icon="el-icon-edit"
                            size="small">修改</el-button>
                        <el-button @click.native.prevent="onClickAppend(scope.row)" type="success"
                            icon="el-icon-circle-plus-outline" size="small">追加</el-button>
                        <el-button @click.native.prevent="onClickLog(scope.row.id)" type="warning"
                            icon="el-icon-notebook-1" size="small">日志</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <!-- ========分组管理弹框END====== -->        
        <!-- ========弹框BEGIN====== -->
        <el-dialog :title="formTitle" :visible.sync="dialogForm" width="720px" :close-on-click-modal="false" center
            class="formparams">
            <el-form :model="form" :label-width="formLabelWidth">
                <el-form-item label="分组名称">
                    <el-input v-model="form.title" placeholder="名称"></el-input>
                </el-form-item>
                <el-form-item label="备注信息">
                    <el-input v-model="form.desc" placeholder="备注"></el-input>
                </el-form-item>
                <el-form-item label="选择跨服区服">
                    <el-button type="primary" icon="el-icon-menu" @click="onSelectItem">区服</el-button>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onAddCrossGroup">确 定</el-button>
                <el-button icon="el-icon-close" @click="dialogForm = false">取 消</el-button>
            </div>
        </el-dialog>
        <!-- ========弹框END====== -->
        <!-- ========物品弹框====== -->
        <el-dialog :title="titleSelectServer" :visible.sync="dialogItemForm" width="1080px" :close-on-click-modal="false"
            :show-close="false" center class="itemdialog">
            <div style="width: 100%">
                <el-row>
                    <el-col :span="4">
                        <el-select v-model="form.group" placeholder="选择区服列表" value-key="id" @change="onFormSelectGroup"
                            style="width:100%;">
                            <el-option v-for="item in serverGroups" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="4">
                        <el-input v-model="search" placeholder="关键字搜索" style="width:100%;"></el-input>
                    </el-col>
                    <el-col :span="4">
                        <el-button type="success" plain style="width:100%;" disabled>&gt;&gt;&gt;&gt;&gt;&gt;</el-button>
                    </el-col>
                    <el-col :span="12">
                        <el-button type="primary" style="width:100%;" disabled>已选择的服务器</el-button>
                    </el-col>
                </el-row>
                <el-row :gutter="0">
                    <el-col :span="8">
                        <el-table :data="filterItems" border style="width: 100%" height="420" class="serverlist"
                            @selection-change="leftSelectionChange">
                            <el-table-column label="ID" prop="id" type="selection"></el-table-column>
                            <el-table-column label="servername" prop="name" show-overflow-tooltip width="100px"></el-table-column>
                            <el-table-column label="显示名称" prop="title" show-overflow-tooltip></el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="primary" @click="onAddItem([scope.row])"
                                        :disabled="editMergeParams&&!appendMergeServer">添加</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                    <el-col :span="4">
                        <el-row>
                            <el-button size="mini" type="primary" @click="onAddItem(null)" style="margin-top:180px;"
                                :disabled="editMergeParams&&!appendMergeServer">批量&gt;&gt;添加</el-button>
                        </el-row>
                        <el-row>
                            <el-button size="mini" type="danger" @click="onRemoveItem(null)"
                                :disabled="editMergeParams">批量&lt;&lt;移除</el-button>
                        </el-row>
                    </el-col>
                    <el-col :span="12">
                        <el-table :data="selectedServers" border style="width: 100%" height="420" class="serverlist"
                            @selection-change="rightSelectionChange" :key="updateDetailRow">
                            <el-table-column label="ID" prop="id" type="selection" show-overflow-tooltip></el-table-column>
                            <el-table-column label="servername" prop="name" show-overflow-tooltip width="100px"></el-table-column>
                            <el-table-column label="显示名称" prop="title" show-overflow-tooltip></el-table-column>
                            <el-table-column label="定时加入" width="196px"> 
                                <template slot-scope="scope">
                                    <el-date-picker v-model="scope.row.join_time" type="datetime"  size="mini" placeholder="时间" style="width: 100%;" v-if="scope.row.showtime" :disabled="scope.row.fixedtime"></el-date-picker>
                                    <el-tag  size="mini" type="success" v-if="!scope.row.showtime">已加入</el-tag >
                                </template>
                            </el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="danger" @click="onRemoveItem([scope.row])"
                                        style="float:right;" :disabled="editMergeParams">移除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onFinishServerSelect"
                    style="margin-right: 16px;">确 定</el-button>
                <el-button type="primary" icon="el-icon-close" @click="dialogItemForm=false" plain
                    style="margin-left: 16px;">取 消</el-button>
            </div>
        </el-dialog>
        <!-- ========弹框END====== -->
        <!-- ========日志弹框BEGIN====== -->
        <el-dialog title="操作日志" :visible.sync="dialogLogForm" @close="onCloseLog" width="60%"
            :close-on-click-modal="false" center class="itemdialog">
            <el-table :data="mergeLog" height="600" style="width: 100%" :cell-style="{padding:4+'px'}"
                class="serverlist" ref="dialogTable">
                <el-table-column prop="id" label="id" width="100"></el-table-column>
                <el-table-column prop="create_time" label="时间" width="200"></el-table-column>
                <el-table-column prop="content" label="信息" show-overflow-tooltip></el-table-column>
            </el-table>
        </el-dialog>
        <!-- ========日志弹框END====== -->
        <!-- ========赛季信息BEGIN====== -->
        <el-dialog title="" :visible.sync="dialogSeasonForm" width="600px" :close-on-click-modal="false" center class="itemdialog">
            <el-row>
                <el-descriptions title="赛季信息" :column="1" border>
                  <el-descriptions-item label="类型名称" content-class-name="desc-item-content-stype">
                      <span style="font-weight: bold;font-size: 16px;color: #000000;">{{seasonInfo.stype}}</span>
                  </el-descriptions-item>                    
                  <el-descriptions-item label="赛季次数" content-class-name="desc-item-content-sno">
                      <span style="font-weight: bold;font-size: 16px;color: #000000;">{{'第'+seasonInfo.sno+'次'}}</span>
                  </el-descriptions-item>
                  <el-descriptions-item label="当前赛季" content-class-name="desc-item-content-scur">
                      <span style="font-weight: bold;font-size: 16px;color: #000000;">{{seasonInfo.cur}}</span>
                  </el-descriptions-item>                  
                  <el-descriptions-item label="下一赛季" content-class-name="desc-item-content-snext">
                      <span style="font-weight: bold;font-size: 16px;color: #000000;">{{seasonInfo.next}}</span>
                  </el-descriptions-item>
                  <el-descriptions-item label="赛季时间">
                    <el-tag size="large" type="warning" style="font-size: 16px;font-weight: bold;">{{'持续'+seasonInfo.days+'天，间隔'+seasonInfo.space+'天'}}</el-tag>
                  </el-descriptions-item>               
                </el-descriptions>
                <el-descriptions :title="formTitle" :column="1" style="margin-top: 36px;" border>
                  <el-descriptions-item label="类型名称">
                      <el-input v-model="seasonForm.stype" placeholder="输入类型名称" @change="onSeasonFormChanged"></el-input>
                  </el-descriptions-item>                    
                  <el-descriptions-item label="赛季开始">
                      <el-date-picker v-model="seasonForm.start_date" type="datetime" placeholder="选择日期时间" @change="onSeasonFormChanged"></el-date-picker>
                  </el-descriptions-item>
                  <el-descriptions-item label="持续天数">
                      <el-input v-model="seasonForm.days" placeholder="输入持续天数" @change="onSeasonFormChanged"></el-input>
                  </el-descriptions-item>
                  <el-descriptions-item label="相隔天数">
                      <el-input v-model="seasonForm.space" placeholder="输入相隔天数" @change="onSeasonFormChanged"></el-input>
                  </el-descriptions-item> 
                  <el-descriptions-item label="确认设置">
                      <el-button type="danger" @click="onClickAddSeaonInfo" style="width: 50%;">提交</el-button>
                  </el-descriptions-item>                                    
                </el-descriptions>   
            </el-row>
        </el-dialog>
        <!-- ========日志弹框END====== -->
    </el-row>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'
    import tool from '../../tool.js'

    export default {
        data() {
            return {
                groupList: [],
                seasonList: [],
                formTitleSeasonGroup:'',
                formTitle: '',
                titleSelectServer: '',
                dialogGroupForm: false,
                dialogForm: false,
                dialogItemForm: false,
                dialogLogForm: false,
                curSeasonid: '', // 标记正在操作的赛季
                form: {
                    id: '',
                    title: '',
                    desc: '',
                },
                dialogSeasonForm: false,
                seasonInfo: { // 赛季信息
                    sno: 0,
                    stype:'',
                    cur: '',
                    next: '',
                    days: '',
                    space: '',
                },
                seasonForm: { // 赛季设置
                    id: '',
                    stype:'',
                    start_date:'',
                    days:'',
                    space:'',
                },
                search: '',
                servers: [], // 可选的区服
                selectedServers: [], // 已选择的区服
                leftSelectRows: [], // 左边选择的项
                rightSelectRows: [], // 右边选择的项
                serverGroups: [],
                editMergeParams: false, // 标识编辑分组参数(无法修改区服项)
                appendMergeServer: 0, // 需追加区服的分组id
                appendServerids: [], // 追加的区服id表
                mergeLog: [], // 日志数据
                lastMergeLogId: 0, // 最新一条日志
                logTimer: null,
                updateDetailRow: 0,
                formLabelWidth: '130px',
                tableHeight: document.documentElement.clientHeight-136,
            }
        },
        created: function() {
            this.refreshGroupList()
            this.refreshSeasonInfo();
        },
        computed: {
            ...mapGetters(
                ['gameHttp']
            ),
            // 物品关键词过滤显示
            filterItems() {
                return this.servers.filter((data) => {
                    // 已选择过的则不用再显示了
                    for (let i = 0; i < this.selectedServers.length; i++) {
                        if (data.id == this.selectedServers[i].id) {
                            return false;
                        }
                    }
                    // 没有搜索条件 或 包含搜索字则显示
                    return !this.search || (data.name.includes(this.search) || data.title.includes(this
                    .search));
                })
            },
        },
        methods: {
            // 刷新列表
            refreshCrossGroupList(seasonid) {
                this.gameHttp(1041, {seasonid:seasonid}).then((response) => {
                    this.groupList = response.data.list;
                })
            },             
            // 刷新赛季信息
            refreshSeasonInfo() {
                this.gameHttp(1048, {op:0}).then((response) => {
                    if (0 == response.data.errcode) {
                        this.seasonList = response.data.list;
                    }
                })
            },            
            // 刷新分组列表
            refreshGroupList() {
                this.gameHttp(2006, {
                    plain: 1
                }).then((response) => {
                    this.serverGroups = response.data.list
                    this.serverGroups.splice(0, 0, {
                        id: -1,
                        name: "全部区服列表"
                    })
                })
            },
            // 刷新日志
            refreshGroupLog(id) {
                this.gameHttp(1044, {id:id}).then((response) => {
                    if (0 == response.data.errcode) {
                        let len = response.data.list.length;
                        if (0 == len) {
                            return;
                        }
                        for (let i = 0; i < len; i++) {
                            this.mergeLog.push(response.data.list[i]);
                        }
                        // 滚动到底部
                        setTimeout(()=>{
                            this.$refs.dialogTable.bodyWrapper.scrollTop = this.$refs.dialogTable.bodyWrapper.scrollHeight;                          
                        }, 10);
                    }
                })
            },
            // 新增赛季信息
            onClickAddSeason() {
                this.formTitle = "新增赛季信息";
                this.dialogSeasonForm = true;
                // 参数重置为初始空
                for (let key in this.seasonInfo) {
                    this.seasonInfo[key] = ''
                }
                for (let key in this.seasonForm) {
                    this.seasonForm[key] = ''
                }
            },
            // 编辑赛季信息
            onClickEditSeason(row) {
                this.formTitle = "修改赛季信息";
                this.dialogSeasonForm = true;
                this.seasonInfo = row;
                this.seasonForm.id = this.seasonInfo.id;
                this.seasonForm.stype = this.seasonInfo.stype;
                this.seasonForm.days = this.seasonInfo.days;
                this.seasonForm.space = this.seasonInfo.space;
                if (this.seasonInfo.start_date) {
                    this.seasonForm.start_date = new Date(this.seasonInfo.start_date);
                }
            },
            // 赛季分组管理
            onClickSeasonGroup(row) {
                this.dialogGroupForm = true;
                this.curSeasonid = row.id; // 标记赛季id                
                this.formTitleSeasonGroup = "【" + row.stype + "】分组管理";
                this.refreshCrossGroupList(row.id);
            },          
            // 关闭日志
            onCloseLog() {
                clearInterval(this.logTimer);
            },
            // 弹出新增编辑框
            onClickAddGroup() {
                this.editMergeParams = false;
                this.appendMergeServer = 0;
                this.formTitle = '新增分组'
                this.dialogForm = true
                this.servers = []
                this.selectedServers = []
                this.leftSelectRows = []
                this.rightSelectRows = []
                for (let k in this.form) {
                    this.form[k] = ''
                }
            },
            // 弹出修改编辑框
            onClickEdit(row) {
                this.editMergeParams = true;
                this.appendMergeServer = 0;
                this.formTitle = '修改分组'
                this.dialogForm = true
                this.servers = []
                this.selectedServers = JSON.parse(JSON.stringify(row.servers))
                this.leftSelectRows = []
                this.rightSelectRows = []
                // 参数拷贝显示
                this.form = JSON.parse(JSON.stringify(row))
                delete this.form.update_time // 忽略不需要提交的字段
                delete this.form.create_time // 忽略不需要提交的字段
                // 格式化日期
                for (let n=0; n<this.selectedServers.length; n++) {
                    if ("FIXED" == this.selectedServers[n].join_time) { // 定时加入时间
                        this.selectedServers[n].join_time = "";
                    } else {
                        this.selectedServers[n].join_time = new Date( this.selectedServers[n].join_time );
                    }
                    this.selectedServers[n].fixedtime = false; // 可修改时间
                }
                this.updateDetailRow ++;
            },
            // 弹出追加区服
            onClickAppend(row) {
                this.titleSelectServer = "选择要追加的区服";
                this.appendMergeServer = row.id;
                this.appendServerids = [];
                this.editMergeParams = true;
                this.dialogItemForm = true
                this.servers = []
                this.selectedServers = JSON.parse(JSON.stringify(row.servers))
                this.leftSelectRows = []
                this.rightSelectRows = []
                // 参数拷贝显示
                this.form = JSON.parse(JSON.stringify(row))
                delete this.form.update_time // 忽略不需要提交的字段
                delete this.form.create_time // 忽略不需要提交的字段
                // 格式化日期
                for (let n=0; n<this.selectedServers.length; n++) {
                    if ("FIXED" == this.selectedServers[n].join_time) { // 定时加入时间
                        this.selectedServers[n].join_time = "";
                    } else {
                        this.selectedServers[n].join_time = new Date( this.selectedServers[n].join_time );
                    }
                    this.selectedServers[n].fixedtime = true; // 追加模式下，已存在的不能修改时间
                }
                this.updateDetailRow ++;
            },
            // 操作日志
            onClickLog(merid) {
                this.lastMergeLogId = 0;
                this.mergeLog = [];
                this.refreshGroupLog(merid);
                this.dialogLogForm = true;
            },
            // 弹出选择服务器
            onSelectItem() {
                this.dialogItemForm = true
                this.titleSelectServer = "选择要跨服的区服";
            },
            // 表单选择分组
            onFormSelectGroup(id) {
                this.gameHttp(2009, { // 拉取服务器列表
                    listid: id,
                    avlcro: this.curSeasonid, // 指定过滤能用的
                }).then((response) => {
                    this.servers = response.data.list
                })
            },
            // 完成区服的选择
            onFinishServerSelect() {
                if (this.appendMergeServer) { // 追加区服判断
                    // 区服id表
                    if (0 == this.appendServerids.length) {
                        this.$message.error('请指定需要追加到分组的区服');
                        return;
                    }
                    // 收集serverids
                    let serverids = [];
                    for (let i=0; i<this.appendServerids.length; i++) {
                        let svrid = this.appendServerids[i];
                        for (let n=0; n<this.selectedServers.length; n++) {
                            let info = this.selectedServers[n];
                            if (svrid == info.id) {
                                serverids.push( {serverid : svrid, join_time : (info.join_time ? tool.dateFormat(info.join_time) : '')} )
                                break;
                            }
                        }
                    }
                    // 请求追加
                    let rqs = {
                        id: this.appendMergeServer,
                        seasonid: this.curSeasonid,
                        serverids: serverids
                    };
                    console.log(rqs);
                    this.gameHttp(1043, rqs).then((response) => {
                        if (0 == response.data.errcode) {
                            this.refreshCrossGroupList(this.curSeasonid);
                            this.$message.success("提交成功");
                        } else {
                            this.$message.error(response.data.errmsg);
                        }
                    })
                }
                this.dialogItemForm = false;
            },
            // 处理左边多选变化
            leftSelectionChange(rows) {
                this.leftSelectRows = rows;
            },
            // 处理右边多选变化
            rightSelectionChange(rows) {
                this.rightSelectRows = rows;
            },
            // 添加区服到选择列
            onAddItem(rows) {
                if (!rows) {
                    rows = this.leftSelectRows;
                }
                for (let i = 0; i < rows.length; i++) {
                    let item = JSON.parse( JSON.stringify(rows[i]) );
                    item.join_time=""; item.showtime = true; // 新加的需显示定时设置
                    item.fixedtime = false; // 改加的当然可改时间
                    this.selectedServers.push(item);
                    if (this.appendMergeServer) { // 追加模式提取追加的id
                        this.appendServerids.push(rows[i].id);
                    }
                }
            },
            // 移除选择的区服
            onRemoveItem(rows) {
                if (!rows) {
                    rows = this.rightSelectRows;
                }
                let funcRomove = (row) => {
                    for (let i = 0; i < this.selectedServers.length; i++) {
                        if (row.id == this.selectedServers[i].id) {
                            this.selectedServers.splice(i, 1);
                            // 左边列表不存在的则压入到左边
                            for (let j = 0; j < this.servers.length; j++) {
                                if (row.id == this.servers[j].id) {
                                    return;
                                }
                            }
                            this.servers.push(row);
                            break;
                        }
                    }
                };
                for (let r = 0; r < rows.length; r++) {
                    funcRomove(rows[r]);
                }
            },
            // 添加分组
            onAddCrossGroup() {
                if (0 == this.form.title.length) {
                    this.$message.error('请指定名称！');
                    return;
                }
                // 区服id表
                let serverids = [];
                for (let i = 0; i < this.selectedServers.length; i++) {
                    let item = {
                        serverid : this.selectedServers[i].id,
                        join_time: this.selectedServers[i].join_time ? tool.dateFormat(this.selectedServers[i].join_time) : '',
                    };
                    serverids.push(item);
                }
                if (0 == serverids.length) {
                    this.$message.error('请选择区服');
                    return;
                }
                this.dialogForm = false
                // 请求操作                      
                let rqs = JSON.parse(JSON.stringify(this.form));
                rqs.serverids = serverids;
                rqs.seasonid = this.curSeasonid;
                console.log(rqs);
                this.gameHttp(1042, rqs).then((response) => {
                    if (0 == response.data.errcode) {
                        this.refreshCrossGroupList(this.curSeasonid)
                        this.$message.success("提交成功")
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                })
            },  
            // 赛季信息变化监听
            onSeasonFormChanged() {
                console.log(JSON.stringify(this.seasonForm));
                this.seasonInfo.stype = this.seasonForm.stype;
                this.seasonInfo.days = this.seasonForm.days;
                this.seasonInfo.space = this.seasonForm.space;
                if (!this.seasonForm.days || !this.seasonForm.space || !this.seasonForm.start_date || !this.seasonForm.stype) {
                    return;
                }
                let rqs = {
                    op:1,
                    test:1,
                    stype:this.seasonForm.stype,
                    days:this.seasonForm.days,
                    space:this.seasonForm.space,
                    start_date:tool.dateFormat( this.seasonForm.start_date )
                };
                this.gameHttp(1048, rqs).then((response) => {
                    if (0 == response.data.errcode) {
                        this.seasonInfo.cur = response.data.cur;
                        this.seasonInfo.next = response.data.next;
                    } else {
                        this.$message.error(response.data.errmsg);
                    }
                })
            },
            // 设置赛季信息
            onClickAddSeaonInfo () {
                if (!this.seasonForm.stype) {
                    this.$message.error('请指定赛季类型名称'); return;
                }
                if (!this.seasonForm.start_date) {
                    this.$message.error('请指定赛季开始时间'); return;
                }
                if (!this.seasonForm.days) {
                    this.$message.error('请指定赛季持续时间天'); return;
                }
                if (!this.seasonForm.space) {
                    this.$message.error('请指定赛季间隔时间天'); return;
                }
                this.dialogSeasonForm = false;
                let rqs = {
                    op : 1,  
                    id: this.seasonForm.id,
                    stype : this.seasonForm.stype,
                    start_date : tool.dateFormat( this.seasonForm.start_date ),
                    days : this.seasonForm.days,
                    space : this.seasonForm.space,
                };
                this.gameHttp(1048, rqs).then((response) => {
                    if (0 == response.data.errcode) {
                        this.$message.success("提交成功");
                        this.refreshSeasonInfo();
                    } else {
                        this.$message.error(response.data.errmsg);
                    }
                })
            },
            // 数据表色彩间隔效果     
            tableRowClassName({
                rowIndex
            }) {
                if (0 == rowIndex % 2) {
                    return 'warning-row';
                } else {
                    return 'success-row';
                }
            },
        },
    }
</script>

<style>
    .serverlist.el-table .warning-row {
        background: #f5f7fa;
    }

    .serverlist.el-table .success-row {
        background: #ffffff;
    }
    
    .serverlist.el-table .error-row {
        color: #ff0000;
        font-weight: bold;
    }   
    .serverlist.el-table .warnlog-row {
        color: #ffaa00;
        font-weight: bold;
    }       

    .serverlist.el-table {
        margin-top: 16px;
    }

    .serverlist {
        font-size: 12px;
    }

    .desc-item-content-stype {
        background: #F56C6C;
        font-weight: bold;
    }
    .desc-item-content-sno {
        background: #409EFF;
        font-weight: bold;
    }
    .desc-item-content-scur {
        background: #67C23A;
    }    
    .desc-item-content-snext {
        background: #C0C4CC;
    }    
</style>
